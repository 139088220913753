import React, { useState } from "react"
import QueryString from "query-string"
import { devLog, translatePath } from "../../utils"
import PageRoot from "../../components/PageRoot"
import ContainerWithPadding from "../../components/ContainerWithPadding"
import { navigate } from "gatsby"
import { INSUFFICIENT_FUNDS } from "../../../functions/lib/error-codes"
import { useTranslation } from "react-i18next"
import CheckoutForm from "../../components/forms/CheckoutForm"
import useBraintree from "../../hooks/useBraintree"
import { Spin } from "antd"

const CheckoutPage = ({ location }) => {
  const { t } = useTranslation()

  const { checkout } = useBraintree()
  const params = QueryString.parse(location.search)
  const { id } = params
  const [paying, setPaying] = useState(false)

  const handleCheckout = async (payload, id) => {
    setPaying(true)
    try {
      const result = await checkout(payload, id)
      navigate(translatePath("/checkout/success") + `?id=${id}`, {
        replace: true,
      })
    } catch (error) {
      const errorObject = {
        code: error.code,
        message: btoa(error.message),
      }

      if (error?.message === "Insufficient Funds") {
        errorObject.code = INSUFFICIENT_FUNDS
      }

      const query = QueryString.stringify(errorObject)
      devLog({ query, errorObject })
      navigate(`${translatePath("/checkout/fail")}?${query}`)
    }
    setPaying(false)
  }

  return (
    <PageRoot title={t("label:payment")} isDark={false}>
      <div className="checkout-form-wrapper">
        <ContainerWithPadding size={"small"} align="center">
          <Spin spinning={paying}>
            <CheckoutForm purchaseId={id} onFinish={handleCheckout} />
          </Spin>
        </ContainerWithPadding>
      </div>
    </PageRoot>
  )
}

export default CheckoutPage
