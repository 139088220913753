import React, { useEffect, useMemo, useState } from "react"
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Row,
  Skeleton,
  Space,
  Spin,
} from "antd"
import {
  devLog,
  formatPrice,
  getImageUrl,
  pushBeginCheckoutEvent,
  translatePath,
  uiHandleError,
} from "../../utils"
import { useTranslation } from "react-i18next"
import useDropin from "../../hooks/useDropin"
import ErrorScreen from "../ErrorScreen"
import { navigate } from "gatsby"
import { gql, useQuery } from "@apollo/client"
import { THREED_SECURE_FAILED } from "../../../functions/lib/error-codes"
import CouponSelector from "../customFormItems/CouponSelector"
import { PERCENTAGE } from "../../enums/DiscountTypes"
import { DEFAULT_LANDSCAPE_IMAGE_SIZE } from "../../constants/imageSizes"
import soisyLogo from "../../../static/assets/images/soisy-black.svg"
import pagolightLogo from "../../../static/assets/images/pagolight.svg"
import klarnaLogo from "../../../static/assets/images/klarna.svg"
import scalaPayLogo from "../../../static/assets/images/scalapay-black.svg"
import ExternalPaymentCard, {
  InfoModalContent,
} from "../../components/payment/InstallmentPayments"
import { useAuth0 } from "@auth0/auth0-react"
import fetch from "cross-fetch"
import { ScalapayWidget } from "../payment/InstallmentWidget"
import { IS_ITALY } from "../../constants/settings"

const SCALAPAY_MIN_PRICE = 5
const SCALAPAY_MAX_PRICE = 2000
const SCALAPAY_PAY_IN_3_THRESHOLD = 900

const colSettings = {
  xs: 24,
  sm: 12,
  md: 8,
  lg: 8,
}

const GET_PURCHASE_QUERY = gql`
  fragment SpecialPrice on special_prices {
    start_at
    end_at
    final_price
  }

  query getPurchase($id: uuid!) {
    user_purchases_by_pk(id: $id) {
      currency
      created_at
      description
      id
      item_id
      price
      special_price
      state
      item_type
      user_id
      VIDEO: video {
        id
        title
        subtitle
        short_description
        duration
        price
        special_price {
          ...SpecialPrice
        }
        price_policy
        landscape {
          s3_key
        }
      }
      COURSE: course {
        id
        title
        subtitle
        short_description
        price
        special_price {
          ...SpecialPrice
        }
        price_policy
        landscape {
          s3_key
        }
      }
      CERTIFICATION: certification {
        id
        title
        subtitle
        short_description
        price
        special_price {
          ...SpecialPrice
        }
        landscape_success {
          s3_key
        }
        landscape_locked {
          s3_key
        }
        wallpaper {
          s3_key
        }
      }
      GIFT_CARD: gift_card {
        id
        title
        subtitle
        short_description
        price
        special_price {
          ...SpecialPrice
        }
        criterion {
          picture {
            s3_key
          }
        }
      }
      BUNDLE: bundle {
        id
        title
        subtitle
        short_description
        price
        special_price {
          ...SpecialPrice
        }
        landscape {
          s3_key
        }
      }
    }
  }
`

const LockOverlay = () => {
  const { t } = useTranslation()

  return (
    <div className="lock-overlay">
      <div
        style={{
          position: "absolute",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          width: "95%",
          height: "100%",
          textAlign: "center",
          zIndex: "10",
          background: "#2d2d2dcc",
          display: "flex",
          alignItems: "center",
          margin: "auto",
          justifyContent: "center",
          borderRadius: "8px",
          backdropFilter: "blur(5px)",
          padding: "30px",
        }}
      >
        <Space direction="vertical">
          <img src="/assets/images/lock.svg" alt="lock" className="lock-icon" />
          <b>
            <span style={{ color: "#FFFFFF" }}>
              {t("label:externalPaymentNotAvailableForThisPrice")}
            </span>
          </b>
        </Space>
      </div>
    </div>
  )
}

const getPagolightToken = async auth0Token => {
  const response = await fetch("/.netlify/functions/pagolight-authorize", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${auth0Token}`,
    },
  })
  if (response.status === 200) {
    return await response.json()
  }

  return null
}

const initPagolight = async ({
  auth0Token,
  pagolightToken,
  purchaseId,
  couponCode,
}) => {
  const response = await fetch("/.netlify/functions/pagolight-init", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${auth0Token}`,
    },
    body: JSON.stringify({
      token: pagolightToken,
      purchaseId,
      couponCode,
    }),
  })

  if (response.status === 200) {
    const data = await response.json()
    return data
  }

  return null
}

const CheckoutForm = ({ onFinish, initialValues, purchaseId }) => {
  const { t } = useTranslation()
  devLog({ purchaseId })
  const { getAccessTokenSilently } = useAuth0()
  const [paying, setPaying] = useState(false)

  const { loading, error, data } = useQuery(GET_PURCHASE_QUERY, {
    variables: { id: purchaseId },
  })

  const [selectedCoupon, setSelectedCoupon] = useState(null)
  const [discount, setDiscount] = useState(0)
  const [finalPrice, setFinalPrice] = useState(0)
  const [specialPrice, setSpecialPrice] = useState(null)
  const [scalapayDisabled, setScalapayDisabled] = useState(false)
  const [klarnaDisabled, setKlarnaDisabled] = useState(false)
  const [paymentLoading, setPaymentLoading] = useState(false)

  useEffect(() => {
    if (loading) {
      return
    }

    if (error) {
      return
    }

    let _discount = 0
    let originalPrice = data?.user_purchases_by_pk?.price
    let _finalPrice = data?.user_purchases_by_pk?.special_price || originalPrice

    if (selectedCoupon) {
      const { value, percentage, discount_type } = selectedCoupon.criterion
      if (discount_type === PERCENTAGE) {
        _discount = originalPrice * percentage
      } else {
        if (value >= _finalPrice) {
          uiHandleError({
            error: new Error(t("error:couponExceeded")),
          })
          setSelectedCoupon(null)
          return
        }
        _discount = value
      }
      _finalPrice = Math.max(0, _finalPrice - _discount)
    }

    setDiscount(_discount)
    setFinalPrice(_finalPrice)
  }, [selectedCoupon, loading, error, data])

  useEffect(() => {
    if (!data) {
      return
    }
    const { item_type, state, price, special_price, item_id, description } =
      data?.user_purchases_by_pk || {}

    if (special_price) {
      setSpecialPrice(special_price)
      setFinalPrice(special_price)
    }

    pushBeginCheckoutEvent({
      id: item_id,
      price: price,
      name: description,
    })
  }, [loading, data])

  const {
    loading: dropinLoading,
    error: dropinError,
    dropinInstance,
    threeDSecureInstance,
    selectedPaymentMethod,
    deviceData,
  } = useDropin("#dropin-container", data?.user_purchases_by_pk?.price)

  useEffect(() => {
    if (!dropinInstance || !finalPrice) {
      return
    }

    dropinInstance.updateConfiguration(
      "paypal",
      "amount",
      finalPrice.toFixed(2)
    )
    dropinInstance.updateConfiguration(
      "paypalCredit",
      "amount",
      finalPrice.toFixed(2)
    )
  }, [dropinInstance, finalPrice])

  const productPreview = useMemo(() => {
    if (loading) {
      return <Skeleton active round />
    }

    if (error) {
      return <ErrorScreen error={error} />
    }

    if (!data?.user_purchases_by_pk) {
      return <ErrorScreen error={new Error(t("error:itemNotFound"))} />
    }

    if (data?.user_purchases_by_pk.state === "COMPLETED") {
      navigate(translatePath("/checkout/success") + `?id=${purchaseId}`, {
        replace: true,
      })
      return null
    }

    const { item_type, state, item_id } = data?.user_purchases_by_pk || {}
    const item = data?.user_purchases_by_pk[item_type]

    const {
      title,
      subtitle,
      short_description,
      price,
      landscape,
      landscape_locked,
      landscape_success,
      criterion,
    } = item || {}

    const coverUrl = getImageUrl(
      landscape_locked || landscape_success || landscape || criterion?.picture,
      DEFAULT_LANDSCAPE_IMAGE_SIZE
    )

    return (
      <div className="purchase-preview-wrapper">
        <Card
          style={{ width: "100%", marginBottom: 50 }}
          cover={<img alt={title} src={coverUrl} />}
          bordered={false}
        >
          <Card.Meta title={title} description={short_description} />
        </Card>
      </div>
    )
  }, [loading, error, data])

  const priceLabel = useMemo(() => {
    if (loading) {
      return <Spin spinning />
    }

    const price = data?.user_purchases_by_pk?.price

    if (price !== finalPrice) {
      return (
        <span>
          <span className="stroked-price">{formatPrice(price)}</span>
          <span className="final-price">{formatPrice(finalPrice)}</span>
        </span>
      )
    }

    return <span>{formatPrice(price)}</span>
  }, [loading, error, data, finalPrice])

  const handleCheckout = async values => {
    devLog("Checking out")
    if (finalPrice <= 0) {
      onFinish(
        {
          nonce: "",
          deviceData: "",
          couponCode: values.coupon_code,
        },
        purchaseId
      )

      return
    }

    dropinInstance.requestPaymentMethod(
      {
        threeDSecure: {
          amount: finalPrice,
          challengeRequested: true,
        },
      },
      (requestPaymentMethodErr, payload) => {
        if (requestPaymentMethodErr) {
          return uiHandleError({ error: requestPaymentMethodErr })
        }

        if (payload.type === "CreditCard") {
          if (!payload.liabilityShifted && !payload.liabilityShiftPossible) {
            return uiHandleError({
              error: new Error(THREED_SECURE_FAILED),
            })
          } else {
            onFinish(
              {
                nonce: payload.nonce,
                deviceData: payload.deviceData,
                couponCode: values.coupon_code,
              },
              purchaseId
            )
          }
        } else {
          onFinish(
            {
              nonce: payload.nonce,
              deviceData: payload.deviceData,
              couponCode: values.coupon_code,
            },
            purchaseId
          )
        }
      }
    )
  }

  const handlePaySoisy = async () => {
    setPaying(true)
    try {
      const authToken = await getAccessTokenSilently()
      const response = await fetch("/.netlify/functions/soisy-create-order", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          purchaseId,
          couponCode: selectedCoupon?.code || "",
        }),
      })
      if (response.status === 200) {
        const res = await response.json()
        await navigate(res.redirectUrl, { replace: true })
      }
    } catch (e) {
      uiHandleError({ error: e })
    }
    setPaying(false)
  }

  const handlePagolight = async () => {
    setPaying(true)
    try {
      const authToken = await getAccessTokenSilently()
      const token = await getPagolightToken(authToken)

      if (!token) {
        uiHandleError({
          error: new Error("Cannot get pagolight token"),
          message: t("error:cannotGetPagolightToken"),
        })

        return
      }

      const pagolightOrderResponse = await initPagolight({
        auth0Token: authToken,
        pagolightToken: token,
        purchaseId,
        couponCode: selectedCoupon?.code || "",
      })

      if (!pagolightOrderResponse) {
        uiHandleError({
          error: new Error("Cannot create pagolight order"),
          message: t("error:cannotCreatePagolightOrder"),
        })

        return
      }

      await navigate(pagolightOrderResponse.redirect_url, { replace: true })
    } catch (e) {
      uiHandleError({ error: e })
    }
    setPaying(false)
  }

  const handlePayScalapay = async () => {
    try {
      const authToken = await getAccessTokenSilently()
      const response = await fetch(
        "/.netlify/functions/scalapay-create-order",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({
            purchaseId,
            couponCode: selectedCoupon?.code || "",
          }),
        }
      )

      if (response.status === 200) {
        const res = await response.json()
        const { final_price, item_id, description, checkoutUrl, user } = res
        await navigate(checkoutUrl)
      } else {
        const res = await response.json()
        uiHandleError({ message: t("error:genericError") })
      }
    } catch (error) {
      uiHandleError({ error })
    }
  }

  useEffect(() => {
    setScalapayDisabled(
      finalPrice < SCALAPAY_MIN_PRICE || finalPrice > SCALAPAY_MAX_PRICE
    )

    setKlarnaDisabled(finalPrice > 3000)
  }, [finalPrice])

  return (
    <>
      <Form
        onFinish={handleCheckout}
        layout={"vertical"}
        initialValues={initialValues}
      >
        {productPreview}
        {/*{couponFormItems}*/}
        <Form.Item label={t("form:label.couponCode")} name="coupon_code">
          <CouponSelector
            isValid={Boolean(selectedCoupon)}
            placeholder={t("form:label.couponCode")}
            size="large"
            itemId={data?.user_purchases_by_pk?.item_id}
            itemType={data?.user_purchases_by_pk?.item_type}
            onCouponSelect={coupon => {
              devLog({ coupon })
              setSelectedCoupon(coupon)
            }}
          />
        </Form.Item>
        <Spin spinning={loading}>
          <div id="dropin-container" />
          {dropinError && <ErrorScreen error={dropinError} />}
        </Spin>
        <br />
        <br />
        <Space>
          <Button
            className="pay-button yellow"
            type="primary"
            disabled={!dropinInstance && finalPrice !== 0}
            htmlType="submit"
          >
            <span>
              {t("message:completeYourPurchase")} - {priceLabel}
            </span>
          </Button>
        </Space>
      </Form>
      {IS_ITALY && (
        <Spin spinning={paymentLoading}>
          <div className={"installment-payment-wrapper"}>
            <Divider>{t("message:orPayWithAnInstallment")}</Divider>
            <Row gutter={[16, 16]}>
              <Col {...colSettings}>
                {klarnaDisabled && <LockOverlay />}
                <ExternalPaymentCard
                  logo={klarnaLogo}
                  onPay={() => {
                    setPaymentLoading(true)
                    navigate(
                      `/checkout/klarna?id=${purchaseId}&coupon=${
                        selectedCoupon?.code || ""
                      }`
                    )
                  }}
                  modalContent={<InfoModalContent paymentMethod={"klarna"} />}
                  buttonText={t("button:payWithKlarna")}
                />
              </Col>
              <Col {...colSettings}>
                <ExternalPaymentCard
                  logo={scalaPayLogo}
                  onPay={() => {
                    setPaymentLoading(true)
                    handlePayScalapay()
                  }}
                  buttonText={t("button:payWithScalapay")}
                  disclaimer={<ScalapayWidget price={finalPrice} />}
                  modalContent={<InfoModalContent paymentMethod={"scalapay"} />}
                />
              </Col>
              {/* <Col {...colSettings}>
              <ExternalPaymentCard
                logo={soisyLogo}
                onPay={() => {
                  setPaymentLoading(true)
                  handlePaySoisy()
                }}
                modalContent={<InfoModalContent paymentMethod={"soisy"} />}
                buttonText={t("button:payWithSoisy")}
                onlyForPrivatePerson={false}
              />
            </Col> */}
              <Col {...colSettings}>
                <ExternalPaymentCard
                  logo={pagolightLogo}
                  onPay={() => {
                    setPaymentLoading(true)
                    handlePagolight()
                  }}
                  modalContent={
                    <InfoModalContent paymentMethod={"pagolight"} />
                  }
                  buttonText={t("button:payWithPagoLight")}
                />
              </Col>
            </Row>
          </div>
        </Spin>
      )}
    </>
  )
}

export default CheckoutForm
