import { useTranslation } from "react-i18next"
import { Button, Card, Modal, Spin } from "antd"
import helpIcon from "../../../static/assets/images/help.svg"
import cartIcon from "../../../static/assets/images/cart.svg"
import React, { useMemo, useState } from "react"
import "./InstallmentPayments.less"

export const InfoModalContent = ({ paymentMethod }) => {
  const { t } = useTranslation()

  const modalData = {
    /* soisy: {
      title: t("label:info.soisy.title"),
      description: t("label:info.soisy.description"),
      privacy: t("label:info.soisy.privacy"),
      features: {
        feature1: t("label:info.soisy.features.feature1"),
        feature2: t("label:info.soisy.features.feature2"),
      },
    }, */
    klarna: {
      title: t("label:info.klarna.title"),
      description: t("label:info.klarna.description"),
      privacy: t("label:info.klarna.privacy"),
      features: {
        feature1: t("label:info.klarna.features.feature1"),
        feature2: t("label:info.klarna.features.feature2"),
        feature3: t("label:info.klarna.features.feature3"),
        feature4: t("label:info.klarna.features.feature4"),
        feature5: t("label:info.klarna.features.feature5"),
        feature6: t("label:info.klarna.features.feature6"),
      },
    },
    scalapay: {
      title: t("label:info.scalapay.title"),
      description: t("label:info.scalapay.description"),
      privacy: t("label:info.scalapay.privacy"),
      features: {
        feature1: t("label:info.scalapay.features.feature1"),
        feature2: t("label:info.scalapay.features.feature2"),
        feature3: t("label:info.scalapay.features.feature3"),
        feature4: t("label:info.scalapay.features.feature4"),
      },
    },
    pagolight: {
      title: t("label:info.pagolight.title"),
      description: t("label:info.pagolight.description"),
      privacy: t("label:info.pagolight.privacy"),
      features: {
        feature1: t("label:info.pagolight.features.feature1"),
        feature2: t("label:info.pagolight.features.feature2"),
      },
    },
  }

  const features = useMemo(() => {
    const installmentFeatures = modalData[paymentMethod].features
    return Object.keys(installmentFeatures).map((feature, index) => (
      <li className="installment-feature" key={index}>
        <span className="feature-label">{installmentFeatures[feature]}</span>
      </li>
    ))
  }, [modalData])

  return (
    <>
      <h3>{modalData[paymentMethod].title}</h3>
      <p>{modalData[paymentMethod].description}</p>
      <ul>{features}</ul>
      <span className={"privacy"}>{modalData[paymentMethod].privacy}</span>
    </>
  )
}

const ExternalPaymentCard = ({
  onPay,
  buttonText,
  modalContent,
  logo,
  disclaimer,
  onlyForPrivatePerson = true,
}) => {
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <Card cover={<img alt="card-logo" src={logo} />} bordered={false}>
        <p className={"install-payment"}>
          {!disclaimer ? (
            <>
              {t("label:installmentPayment")}
              {!onlyForPrivatePerson ? (
                <>
                  <br />
                  {t("label:onlyForPrivatePerson")}
                </>
              ) : (
                <>
                  <br />
                  {t("label:availableForVat")}
                </>
              )}
            </>
          ) : (
            disclaimer
          )}
        </p>
        <Button
          className="yellowsp installment-pay-button"
          type="primary"
          onClick={onPay}
        >
          <img alt="cart" src={cartIcon} />
          {buttonText}
        </Button>
        <div className={"card-footer"}>
          {modalContent && (
            <>
              {t("button:moreInfo")}
              <button
                className="modal-opener"
                type="primary"
                onClick={e => {
                  e.preventDefault()
                  setIsModalOpen(true)
                }}
              >
                <img alt="help" src={helpIcon} />
              </button>
            </>
          )}
        </div>
      </Card>
      {modalContent && (
        <>
          <Modal
            title={<img alt="logo" src={logo} />}
            visible={isModalOpen}
            closable={true}
            onCancel={e => {
              e.preventDefault()
              setIsModalOpen(false)
            }}
            footer={null}
            className={"installment-info-modal"}
          >
            {modalContent}
            <Button
              className="yellowsp installment-pay-button"
              type="primary"
              onClick={onPay}
            >
              <img alt="cart" src={cartIcon} />
              {buttonText}
            </Button>
          </Modal>
        </>
      )}
    </>
  )
}

export default ExternalPaymentCard
